import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { COLOR_FONT_0, COLOR_LINK_0, COLOR_SURFACE_1 } from '../colors';

import { LandingCenter, LandingLayout } from '../layout';

/**
 *
 */
const Title = styled.h1`
  margin-top: 24px;
  line-height: 32px;
  font-size: 24px;
  font-weight: 400;
`;

/**
 *
 */
const Subtitle = styled.h2`
  margin-top: 16px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 400;
`;

/**
 *
 */
const Explanation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 0px 16px 16px 16px;
  background-color: ${COLOR_SURFACE_1};
  border-radius: 4px;
  text-align: justify;
  font-weight: 400;

  p {
    margin-top: 16px;
  }
`;

/**
 *
 */
const AboutOSSContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

/**
 *
 */
const AboutOSSLink = styled.a`
  display: inline-block;
  margin: 16px 16px 0 0;
  padding: 0 16px;
  line-height: 40px;
  border: 2px solid ${COLOR_FONT_0};
  border-radius: 4px;
  font-weight: 400;
  color: ${COLOR_FONT_0};

  &:visited {
    color: ${COLOR_FONT_0};
  }

  &:hover {
    border-color: ${COLOR_LINK_0};
  }
`;

/**
 *
 */
const StyledAbout = styled.div`
  min-height: 1256px;
`;

export function About() {
  return (
    <LandingLayout>
      <StyledAbout>
        <Helmet>
          <title>About | StackTrends</title>
          <meta name="description" content="" />
        </Helmet>

        <LandingCenter>
          <Title>About</Title>

          <Subtitle>StackTrends</Subtitle>
          <Explanation>
            <p>
              StackTrends started off as a proof-of-concept side project that got perpetually iterated on because it was
              my test-bed for trying out new liraries and frameworks. Whenever a new technology was proposed at work I
              would implement it here first to see how it handled; it was a low-risk environment and had plenty of data
              to work with for testing at scale.
            </p>
            <p>
              At some unknown point StackTrends became a serious thing for me. It may have been because people knew it
              existed when I went to conferences or when I started to get emails from people asking questions about the
              data, I'm not entirely sure. Now I devote as much time as I'm able (and test new technologies out at work,
              instead ;) ).
            </p>
          </Explanation>

          <Subtitle id="about-me">Me</Subtitle>
          <Explanation>
            <p>
              Hello! I'm{' '}
              <a href="https://cernek.dev/" target="_blank" rel="noopener noreferrer">
                Andrew Cernek
              </a>
              , a software engineer from Phoenix. Thanks for checking out StackTrends, it's great to have you! Please
              feel free to contact me if you have ideas for StackTrends, to tell me about how you're using it, or for
              any business-related inquiries. I can be reached through email at andrew@cernek.dev,{' '}
              <a href="https://www.linkedin.com/in/andrewcernek/" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
              , or{' '}
              <a href="https://twitter.com/AndrewCernek" target="_blank" rel="noopener noreferrer">
                Twitter
              </a>
              .
            </p>
          </Explanation>

          <Subtitle id="about-dedication">Dedication</Subtitle>
          <Explanation>
            <p>
              StackTrends is dedicated to the thousands and thousands of people in the software community who have
              taught me everything I know without ever asking for anything in return. Though it's a fun personal
              project, StackTrends is primarily my way of giving back to that community. It doesn't make a dent in what
              I owe, but I hope it's useful to other developers, or at least fun to explore.
            </p>
            <p>
              Personal thanks to my family for all of their support, my best friend and coding mentor{' '}
              <a href="https://github.com/natpen" target="_blank" rel="noopener noreferrer">
                Natalie Pendragon
              </a>
              , and the crew over at{' '}
              <a href="http://www.spyfu.com" target="_blank" rel="noopener noreferrer">
                SpyFu
              </a>{' '}
              for giving me my first job in tech.
            </p>
            <p>
              A shoutout to all the contributors at{' '}
              <a href="https://stackoverflow.com/" target="_blank" rel="noopener noreferrer">
                Stack Overflow
              </a>{' '}
              whose help I've used more times than I can count. You keep the developer world turning and help new
              developers find their way.
            </p>
            <p>
              Finally, massive appreciation to the creators and maintainers of Open-Source Software. I can't imagine
              what the software ecosystem would look like without you, let alone my career. StackTrends would not exist
              without you.
            </p>
          </Explanation>

          <Subtitle id="about-oss">Core OSS Used by StackTrends</Subtitle>
          <AboutOSSContainer>
            <AboutOSSLink href="https://www.amcharts.com/" target="_blank" rel="noopener noreferrer">
              AMCharts
            </AboutOSSLink>
            <AboutOSSLink href="https://github.com/mzabriskie/axios" target="_blank" rel="noopener noreferrer">
              Axios
            </AboutOSSLink>
            <AboutOSSLink href="http://www.chartjs.org/docs/latest/" target="_blank" rel="noopener noreferrer">
              ChartJS
            </AboutOSSLink>
            <AboutOSSLink href="http://fontawesome.io/" target="_blank" rel="noopener noreferrer">
              Font Awesome
            </AboutOSSLink>
            <AboutOSSLink href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">
              Gatsby
            </AboutOSSLink>
            <AboutOSSLink href="https://github.com/SBoudrias/Inquirer.js/" target="_blank" rel="noopener noreferrer">
              Inquirer
            </AboutOSSLink>
            <AboutOSSLink href="https://jestjs.io/" target="_blank" rel="noopener noreferrer">
              Jest
            </AboutOSSLink>
            <AboutOSSLink href="https://nestjs.com/" target="_blank" rel="noopener noreferrer">
              nestjs
            </AboutOSSLink>
            <AboutOSSLink href="https://nodejs.org/en/" target="_blank" rel="noopener noreferrer">
              Node
            </AboutOSSLink>
            <AboutOSSLink href="https://www.npmjs.com/" target="_blank" rel="noopener noreferrer">
              NPM
            </AboutOSSLink>
            <AboutOSSLink href="https://node-postgres.com/" target="_blank" rel="noopener noreferrer">
              pg
            </AboutOSSLink>
            <AboutOSSLink href="https://www.postgresql.org/" target="_blank" rel="noopener noreferrer">
              Postgres
            </AboutOSSLink>
            <AboutOSSLink href="https://prettier.io/" target="_blank" rel="noopener noreferrer">
              Prettier
            </AboutOSSLink>
            <AboutOSSLink href="https://github.com/GoogleChrome/puppeteer" target="_blank" rel="noopener noreferrer">
              Puppeteer
            </AboutOSSLink>
            <AboutOSSLink href="https://facebook.github.io/react/" target="_blank" rel="noopener noreferrer">
              React
            </AboutOSSLink>
            <AboutOSSLink href="https://github.com/gor181/react-chartjs-2" target="_blank" rel="noopener noreferrer">
              React ChartJS 2
            </AboutOSSLink>
            <AboutOSSLink href="https://sequelize.org/" target="_blank" rel="noopener noreferrer">
              Sequelize
            </AboutOSSLink>
            <AboutOSSLink href="https://www.styled-components.com/" target="_blank" rel="noopener noreferrer">
              styled components
            </AboutOSSLink>
            <AboutOSSLink href="https://palantir.github.io/tslint/" target="_blank" rel="noopener noreferrer">
              TSLint
            </AboutOSSLink>
            <AboutOSSLink href="https://www.typescriptlang.org/" target="_blank" rel="noopener noreferrer">
              TypeScript
            </AboutOSSLink>
            <AboutOSSLink href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer">
              Visual Studio Code
            </AboutOSSLink>
          </AboutOSSContainer>
        </LandingCenter>
      </StyledAbout>
    </LandingLayout>
  );
}

export default About;
